<template>
  <section class="invoice-preview-wrapper">

    <div class="position-relative">
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <b-card class="m-0 mb-1">
          <div class="d-flex justify-content-between flex-wrap row-gap-20">
            <h2 class="my-auto">
              {{ formTitle }} <br> <br>
            </h2>
            <b-button
              v-if="canCreateForm()"
              class="mt-1 float-right my-auto"
              variant="primary"
              @click="createFormulaire"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Nouvelle fiche</span>
            </b-button>
          </div>

          <hr class="my-2">

          <!-- module de recherche -->
          <app-collapse>
            <app-collapse-item title="Filtrage et exportation">
              <!-- filtres -->
              <b-row>
                <b-col lg="4">
                  <div class="d-flex flex-column col-lg-6 col-md-6 nopadding">
                    <div class="text-center">
                      <strong>Rechercher avec CodeQR</strong>
                    </div>
                    <br>
                    <div class="my-auto border-primary">
                      <qrcode-drop-zone
                        @decode="onDecode"
                        @dragover="onDragOver"
                      >
                        <div
                          v-if="!loadQR"
                          style="height: 70px"
                          class="d-flex justify-content-center"
                          :class="{
                            'border-success bg-success': draggingOver,
                          }"
                        >
                          <div class="my-auto mx-auto text-center">
                            <strong v-if="!loadQR">Glisser et déposer <br>
                              CodeQR</strong>
                            <strong
                              v-else
                              class="border-success bg-success"
                            >CodeQR Chargé</strong>
                          </div>
                        </div>
                        <div
                          v-else
                          style="height: 70px"
                          class="d-flex justify-content-center bg-success"
                        >
                          <div class="my-auto">
                            <strong class="border-success">CodeQR Chargé</strong>
                          </div>
                        </div>
                      </qrcode-drop-zone>
                    </div>
                    <div>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="primary"
                        class="col-12"
                        @click="useCamera = !useCamera"
                      >
                        Scanner
                      </b-button>
                    </div>
                    <div
                      v-if="useCamera"
                      class="mx-1 my-auto border-primary"
                      style="height: 125px; width 50px;"
                    >
                      <qrcode-stream @decode="onDecode" />
                    </div>
                  </div>
                </b-col>
                <b-col lg="8">
                  <!-- recherche par periode -->
                  <div class="d-flex flex-row-reverse nopadding">
                    <b-form-group
                      class="col pr-0 pl-0"
                      label-cols-xl="3"
                      label="Rechercher par période d'enregistrement"
                    >
                      <b-input-group>
                        <DateRangeWidget
                          v-model="filterBy.periode"
                          :range="filterBy.periode"
                          placeholder="Filtrer par date"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <!-- recherche par reference -->
                  <div class="d-flex flex-row-reverse nopadding">
                    <b-form-group
                      class="col pr-0 pl-0"
                      label-cols-xl="3"
                      label="Rechercher par la référence"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="filterBy.reference"
                          type="text"
                          class="form-control"
                          placeholder="Entrer la référence du formulaire. Exemple: qTkd5dp "
                          @keyup.enter="getListFormulaires()"
                        />
                        <b-input-group-append
                          is-text
                          @click="
                            () => {
                              filterBy.reference = null;
                              resetListWhenResetFilter()
                            }
                          "
                        >
                          <feather-icon
                            v-b-tooltip.hover.bottom="'Vider le champ!'"
                            icon="XCircleIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div>
                    <!-- nom etablissement  -->
                    <div
                      v-if="!estUneFicheFrontiere && !isAgentPolice && !isPromoteur && !isAgent"
                      class="d-flex flex-row-reverse nopadding"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par nom d'établissement"
                      >
                        <v-select
                          v-model="filterBy.nomEtablissement"
                          placeholder="Entrer le nom de l'établissement. Exemple: Les palmiers"
                          :clearable="true"
                          label="nomEtablissement"
                          :options="etablissementsList"
                          @keyup.enter="getListFormulaires()"
                        />
                      </b-form-group>
                    </div>
                    <!-- commissariat -->
                    <div
                      v-if="estUneFicheFrontiere && !isAgentPolice && !isCommissaire && isSuperAdmin"
                      class="d-flex flex-row-reverse nopadding"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        input-cols-xl="9"
                        label="Rechercher par Commissariat associé"
                      >
                        <b-input-group>
                          <v-select
                            v-model="filterBy.commissariat"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="nom"
                            :reduce="option => option._id"
                            :options="tableDataCommissariats"
                            placeholder="Choisissez un commissariat. Exemple: Ilacondji"
                            @keyup.enter="getListFormulaires()"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- nom-->
                    <div
                      v-if="(estUneFicheCliente && (isPromoteur || isAgent || isSuperAdmin)) || estUneFicheFrontiere"
                      class="d-flex flex-row-reverse nopadding"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par le nom"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="filterBy.lastname"
                            type="text"
                            class="form-control"
                            placeholder="Entrer le nom du client. Exemple: ATTOU"
                            @keyup.enter="getListFormulaires()"
                          />
                          <b-input-group-append
                            is-text
                            @click="
                              () => {
                                filterBy.lastname = null;
                                resetListWhenResetFilter()
                              }
                            "
                          >
                            <feather-icon
                              v-b-tooltip.hover.bottom="'Vider le champ!'"
                              icon="XCircleIcon"
                              class="cursor-pointer"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- prenom -->
                    <div
                    v-if="(estUneFicheCliente && (isPromoteur || isAgent || isSuperAdmin)) || estUneFicheFrontiere"
                      class="d-flex flex-row-reverse nopadding"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par le prénom"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="filterBy.firstname"
                            type="text"
                            class="form-control"
                            placeholder="Entrer le prénom du client. Exemple: Luc"
                            @keyup.enter="getListFormulaires()"
                          />
                          <b-input-group-append
                            is-text
                            @click="
                              () => {
                                filterBy.firstname = null;
                                resetListWhenResetFilter()
                              }
                            "
                          >
                            <feather-icon
                              v-b-tooltip.hover.bottom="'Vider le champ!'"
                              icon="XCircleIcon"
                              class="cursor-pointer"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- dateArrivee client hotel-->
                    <div
                      v-if="(estUneFicheCliente && (isPromoteur || isAgent || isSuperAdmin))"
                      class="d-flex flex-row-reverse nopadding"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par la date d'arrivée du client"
                      >
                        <b-input-group>
                          <flat-pickr
                            v-model="filterBy.dateArrivee"
                            class="form-control"
                            placeholder="Veuillez entrer la date d'arrivée du client"
                            @keyup.enter="getListFormulaires()"
                          />
                          <b-input-group-append
                            is-text
                            @click="
                              () => {
                                filterBy.dateArrivee = null;
                                resetListWhenResetFilter()
                              }
                            "
                          >
                            <feather-icon
                              v-b-tooltip.hover.bottom="'Vider le champ!'"
                              icon="XCircleIcon"
                              class="cursor-pointer"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- date arrivee voyageur -->
                    <div
                      v-if="estUneFicheFrontiereEntree"
                      class="d-flex flex-row-reverse nopadding"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par la date d'entrée du voyageur"
                      >
                        <b-input-group>
                          <flat-pickr
                            v-model="filterBy.date"
                            class="form-control"
                            placeholder="Veuillez entrer la date d'entrée du voyageur"
                          />
                          <b-input-group-append
                            is-text
                            @click="
                              () => {
                                filterBy.date = null;
                                resetListWhenResetFilter()
                              }
                            "
                          >
                            <feather-icon
                              v-b-tooltip.hover.bottom="'Vider le champ!'"
                              icon="XCircleIcon"
                              class="cursor-pointer"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- date depart voyageur -->
                    <div
                      v-if="estUneFicheFrontiereSortie"
                      class="d-flex flex-row-reverse nopadding"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par la date de sortie du voyageur"
                      >
                        <b-input-group>
                          <flat-pickr
                            v-model="filterBy.date"
                            class="form-control"
                            placeholder="Veuillez entrer la date de sortie du voyageur"
                          />
                          <b-input-group-append
                            is-text
                            @click="
                              () => {
                                filterBy.date = null;
                                resetListWhenResetFilter()
                              }
                            "
                          >
                            <feather-icon
                              v-b-tooltip.hover.bottom="'Vider le champ!'"
                              icon="XCircleIcon"
                              class="cursor-pointer"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- annee -->
                    <div class="d-flex flex-row-reverse nopadding"
                      v-if="estUneFicheStatMensuel"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par l'année"
                      >
                        <b-input-group>
                          <v-select
                            v-model="filterBy.annee"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="years"
                            placeholder="Entrer l'année de la fiche. Exemple: 2021"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- mois -->
                    <div class="d-flex flex-row-reverse nopadding"
                      v-if="estUneFicheStatMensuel"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par le mois"
                      >
                        <b-input-group>
                          <v-select
                            v-model="filterBy.mois"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="months"
                            placeholder="Entrer le mois de la fiche. Exemple: Mai"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- departement -->
                    <div class="d-flex flex-row-reverse nopadding"
                      v-if="estUneFicheStatMensuel && !isAgentDdtca"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par le département"
                      >
                        <b-input-group>
                          <v-select
                            v-model="filterBy.departement"
                            placeholder="Choisir un département"
                            :clearable="true"
                            :options="departementsList"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <!-- commune -->
                    <div class="d-flex flex-row-reverse nopadding"
                      v-if="estUneFicheStatMensuel"
                    >
                      <b-form-group
                        class="col pr-0 pl-0"
                        label-cols-xl="3"
                        label="Rechercher par la commune"
                      >
                        <b-input-group>
                          <v-select
                            v-model="filterBy.commune"
                            placeholder="Choisir une commune"
                            :clearable="true"
                            :options="communesList"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>

                  <!-- button -->
                  <b-col cols="12" class="nopadding">
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      v-model="filterStyleAnd"
                    >
                      {{filterStyleAnd ? 'Filtrer avec un ET logique' : 'Filtrer avec un OU logique'}}
                    </b-form-checkbox>
                    <!-- button -->
                    <b-button
                      class="float-right"
                      variant="primary"
                      @click="getListFormulaires()"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        class="mr-25"
                      />
                      <span>Rechercher</span>
                    </b-button>
                  </b-col>
                  

                </b-col>
              </b-row>
              <!-- exportation -->
              <div class="row">
                <!-- down all -->
                <div 
                  class="col-lg-3 col-md-6 mt-1"
                >
                  <b-button
                    class="float-right"
                    variant="primary"
                    block
                    @click="uploadData('allData')"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                      class="mr-25"
                    />
                    <span>Télécharger toutes les données du formulaire</span>
                    <b-spinner
                      v-if="loadingExport"
                      small
                      class="ml-1"
                    />
                  </b-button>
                </div>
                <div class="col-lg-3 col-md-1" />
                <!-- down selection -->
                <div class="col-lg-3 col-md-1" />
                <div
                  v-if="tableData && tableData.length > 0"
                  class="col-lg-3 col-md-4 mt-1"
                >
                  <b-button
                    class="float-right"
                    variant="primary"
                    block
                    @click="uploadData('filteredData')"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                      class="mr-25"
                    />
                    <span>Télécharger les données filtrées</span>
                    <b-spinner
                      v-if="loadingExport"
                      small
                      class="ml-1"
                    />
                  </b-button>
                </div>
              </div>

            </app-collapse-item>
          </app-collapse>

          <!-- <hr class="my-2"> -->
          <hr class="my-2">

          <div class="my-1">
            <b-table
              ref="refFormsTable"
              :items="tableData"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Aucune donnée trouvée"
              :busy="isLoading"
              hover
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle" />
                </div>
              </template>
              <template #head(nom)="data">
                {{ getTableHeaderForName() }}
              </template>

              <template #cell(nom)="data">
                {{ getTableCellContentForNameColumn(data.item) }}
              </template>
              <template #cell(etablissement)="data">
                {{
                  data.item.etablissement &&
                    data.item.etablissement.nomEtablissement
                }}
              </template>
              <template #cell(createdAt)="data">
                {{ replaceSlashByDash( convertDateToLocalString(data.item.createdAt) ) }}
              </template>
              <template #cell(auteur)="data">
                <span  v-if="data.item.auteur && data.item.auteur.firstname">
                  {{ data.item.auteur.firstname + ' ' + data.item.auteur.lastname }}
                </span>
                <span  v-if="data.item.auteur && data.item.auteur.nom">
                  {{ data.item.auteur.nom }}
                </span>
              </template>
              <template #cell(dateArrivee)="data">
                {{ reverseDate(data.item.dateArrivee) }}
              </template>
              <template #cell(statut)="data">
                <div v-if="data.item.statut == 'attente'">
                  <b-badge
                    pill
                    variant="warning"
                    class="badge-glow"
                  >
                    En attente
                  </b-badge>
                </div>
                <div v-if="data.item.statut == 'valide'">
                  <b-badge
                    pill
                    variant="success"
                    class="badge-glow"
                  >
                    Validé
                  </b-badge>
                </div>
                <div v-if="data.item.statut == 'rejette'">
                  <b-badge
                    pill
                    variant="danger"
                    class="badge-glow"
                  >
                    Rejetté</b-badge>
                </div>
              </template>
              <template #cell(date)="data">
                {{ reverseDate(data.item.date) }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  v-if="canViewFormDetails()"
                  variant="primary"
                  class="mx-0"
                  @click="
                    $router.push({
                      name: 'formulaires-details',
                      params: {
                        id: data.item._id,
                        model: selectedForm.apiRoutePrefixe,
                      },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" />
                  <span class="text-nowrap ml-1">Détails</span>
                </b-button>
              </template>
            </b-table>
            <!-- pagination -->
            <div>
              <PaginationComponent
                :table-data="tableData"
                :per-page="paginationData.perPage"
                :total-rows="paginationData.total"
                @change="onPaginationChange"
              />
            </div>
          </div>
        </b-card>
      </b-card>
    </div>

    <!-- <div class="main-grid"> -->
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import utilsService from '@/services/utils/utils.service'

import formulaireStoreModule from '@/store/formulaire'
import etablissementsStroreModule from '@/store/etablissements'
import usersStoreModule from '@/store/users'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { buildFiltersForRequest } from '@/helpers/http-utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    flatPickr,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,

    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    ToastificationContent,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'formulaire', module: formulaireStoreModule },
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'users', module: usersStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    const { isEtablissementUser, isAdminEtablissement, isAgentEtablissement } = utilsService.currentUserUtils()

    const departementsList = utilsService.getDepartements()
    const communesList = utilsService.getCommunes()

    return {
      departementsList,
      communesList,
      isAdminEtablissement,
      isAgentEtablissement,
      isEtablissementUser,
      requiredStoreModules,
    }
  },
  data() {
    const { 
      isAgentPolice, isCommissaire, isSuperAdmin, isAgentMtca, userData,
      isAgentDdtca,
    } = utilsService.currentUserUtils()
    return {
      isAgentPolice,
      isCommissaire,
      isSuperAdmin,
      isAgentMtca,
      isAgentDdtca,
      userData,
      etablissementsList: [],
      isLoadingEtablissementsList: true,
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: ['a', 'b', 'b'],
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: true,
        },
        colors: [$themeColors.primary, $themeColors.warning],
        plotOptions: {
          bar: {
            columnWidth: '17%',
            endingShape: 'rounded',
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#6E6B7B',
              fontSize: '0.86rem',
              fontFamily: 'Montserrat',
            },
          },
        },
      },
      series: [
        {
          name: 'Fiches',
          data: [95, 177, 84],
        },
      ],
      loadingExport: false,

      loading: false,
      statsLoad: null,

      lastestRecordedForm: null,

      filterBy: {
        periode: {},
        reference: null,
        firstname: null,
        lastname: null,
        nomEtablissement: null,
        commissariat: null,
        annee: null,
        mois: null,
        dateArrivee: null,
        date: null,
        departement: null,
        commune: null,
      },
      filterStyleAnd: false,
      periode: null,
      reference: null,
      firstname: null,
      lastname: null,

      curPage: 1,
      perPage: 20,
      query: {
        options: {},
        filter: {},
      },
      tableColumns: [],

      tableData: [],
      tableDataCommissariats: [],

      filteredTableData: [],
      formTitle: this.$route.params.title || '',
      selectedForm: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 15,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      isLoading: false,
      loadingData: true,
      draggingOver: false,
      model: '',
      useCamera: false,
      loadQR: false,

      optionsStats: [
        { id: 1, label: '3 derniers trimestres', value: '3t' },
        { id: 2, label: '3 derniers semestres', value: '3s' },
        { id: 3, label: '3 dernières années', value: '3a' },
      ],
      selectedStats: { id: 3, label: '3 dernières années', value: '3a' },
      slug: null,

      totalRows: 0,

      months: utilsService.getListMonths(),
      years: utilsService.getListYears(),
    }
  },

  computed: {
    estUneFicheCliente() {
      return this.selectedForm && this.selectedForm.type === 'client'
    },
    estUneFicheStatMensuel() {
      return this.selectedForm && this.selectedForm.type === 'etablissement'
    },
    estUneFicheFrontiere() {
      return this.selectedForm && this.selectedForm.sousType === 'frontiere'
    },
    estUneFicheFrontiereEntree() {
      return this.selectedForm && this.selectedForm.transit === 'entree'
    },
    estUneFicheFrontiereSortie() {
      return this.selectedForm && this.selectedForm.transit === 'sortie'
    },
    isAdminOrAgentMTCA() {
      const { currentUserId, userRole } = utilsService.currentUserUtils()
      return userRole.code === 'agent_mtca' || userRole.code === 'super_admin'
    },
    isPromoteur() {
      const { currentUserId, userRole } = utilsService.currentUserUtils()
      return userRole.code === 'promo_etablissement'
    },
    isAgent() {
      const { currentUserId, userRole } = utilsService.currentUserUtils()
      return userRole.code === 'agent_etablissement'
    },
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        const { slug } = val.params
        this.slug = slug
        this.selectedForm = utilsService.getFormulaireBySlug(slug)
        this.formTitle = utilsService.getFormulaireTitleBySlug(slug)
      
        
        // add column -----------------------------------------------
        this.tableColumns = []
        this.tableColumns.push(
          {
            key: 'reference', label: 'Ref', sortable: true,
          },
          )
        // add columns by type form type
        const { type, sousType } = this.selectedForm

        if(this.isPromoteur || this.isAgent){
          this.query.options = {
            etablissement: this.userData.etablissement._id,
          }
        }
        // frontiere
        if(sousType === 'frontiere'){
          if(this.isSuperAdmin || this.isAgentMtca){
            this.tableColumns.push(
              {
                key: 'commissariat.nom', label: 'Commissariat ', sortable: true,
              },
            )
          }
          if(this.isAgentPolice){
            this.query.options = {
              commissariat: this.userData.commissariatAssociated._id,
              auteur: this.userData._id,
            }
          }
          if(this.isCommissaire){
            this.query.options = {
              commissariat: this.userData._id,
            }
            this.tableColumns.push(
              {
                key: 'auteur', label: 'Agent Enquêteur', sortable: true,
              },
            )
          }
          this.tableColumns.push(
            {
              key: 'lastname', label: 'Nom Voyageur', sortable: true,
            },
            {
              key: 'firstname', label: 'Prénom Voyageur', sortable: true,
            },
            {
              key: 'date', label: 'Date d\'entrée', sortable: true,
            },
          )
          if(this.estUneFicheFrontiereEntree){
            this.tableColumns.push(
              {
                key: 'date', label: 'Date d\'entrée', sortable: true,
              },
            )
          }
          if(this.estUneFicheFrontiereSortie){
            this.tableColumns.push(
              {
                key: 'date', label: 'Date de sortie', sortable: true,
              },
            )
          }
        }
        if (type === 'etablissement') {
          this.tableColumns.push(
            {
              key: 'nom', label: 'Nom Établissement', sortable: true,
            },
            {
              key: 'departement', label: 'Département', sortable: true,
            },
            {
              key: 'commune', label: 'Commune', sortable: true,
            },
            {
              key: 'annee', label: 'Année', sortable: true,
            },
            {
              key: 'mois', label: 'Mois', sortable: true,
            },
            {
              key: 'statut', label: "Statut Validation", sortable: true,
            },
          )
        }
        if (type === 'client') {
          this.tableColumns.push(
            {
              key: 'lastname', label: 'Nom Client', sortable: true,
            },
            {
              key: 'firstname', label: 'Prénom Client', sortable: true,
            },
            {
              key: 'dateArrivee', label: 'Date d\'arrivée du client', sortable: true,
            },
          )
          if (!this.isEtablissementUser) {
            this.tableColumns.push({
              key: 'etablissement', label: 'établissement', sortable: true,
            })
          }
        }
        this.tableColumns.push(
          {
            key: 'createdAt', label: "Date d'ajout", sortable: true,
          },
          { key: 'actions', },
        )
        // /add column -----------------------------------------------
        if (this.isAdminOrAgentMTCA) {
          await this.getListEtablissements()
          this.getListCommissariats()
        }
        this.getListFormulaires()
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.getListFormulaires()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.getListFormulaires()
      },
    },
    // watch for reset filter ----------------
    'filterBy.periode.from': {
      immediate: false,
      deep: true,
      handler(val, old) {
        if(!val){
          this.resetListWhenResetFilter()
        }
      },
    },
    'filterBy.nomEtablissement': {
      immediate: false,
      deep: true,
      handler(val, old) {
        if(!val){
          this.resetListWhenResetFilter()
        }
      },
    },
    'filterBy.commissariat': {
      immediate: false,
      deep: true,
      handler(val, old) {
        if(!val){
          this.resetListWhenResetFilter()
        }
      },
    },
    'filterBy.annee': {
      immediate: false,
      deep: true,
      handler(val, old) {
        if(!val){
          this.resetListWhenResetFilter()
        }
      },
    },
    'filterBy.mois': {
      immediate: false,
      deep: true,
      handler(val, old) {
        if(!val){
          this.resetListWhenResetFilter()
        }
      },
    },
  },

  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  mounted() {},

  methods: {
    ...mapActions('formulaire', {
      fetchFormulaires: 'fetchFormulaires',
      exportFormulaires: 'exportFormulaires',
    }),
    ...mapActions('etablissements', {
      action_findEtablissement: 'findEtablissement',
    }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    ...mapActions('users', { 
      action_fetchUsers: 'fetchUsers' ,
      actions_fetchCommissariats: 'fetchCommissariats'
    }),
    // -------------------------------------------------------------------------
    getListEtablissements() {
      this.action_findEtablissement({})
        .then(response => {
          this.etablissementsList = response.data.resources
        })
        .catch(err => { console.log('err: ', err) })
    },
    async getListCommissariats(somesParams) {
      const params = {
        options: {
          // role: userRoleId,
        },
        page: this.paginationData.currentPage - 1,
        perPage: 1000,
      }
      this.actions_fetchCommissariats(params)
        .then(async response => {
          this.tableDataCommissariats = response.data.resources || []
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getTableHeaderForName(item) {
      const type = this.selectedForm && this.selectedForm.type
      if (type === 'etablissement') return 'Nom établissement'
      if (type === 'grand_public') return 'Nom'
      if (type === 'client') return 'Nom'
      return 'Nom'
    },
    getTableCellContentForNameColumn(item) {
      const { type, sousType } = this.selectedForm

      if (type === 'etablissement') return item.nomEtablissement
      if (type === 'grand_public') {
        if (sousType === 'aeroport') return item.fullname
        if (sousType === 'frontiere') return item.lastname
        return item.lastname
      }
      if (type === 'client') return item.lastname
      return item.nomEtablissement || item.lastname
    },
    getTableCellContentFoProfileColumn(item) {
      const { auteur, auteurRole } = item
      if (!auteur) return 'Grand public'
      return (auteurRole && auteurRole.nom) || '-'
    },
    createFormulaire() {
      const { params } = this.selectedForm.createRouteProps
      this.$router.push({
        ...this.selectedForm.createRouteProps,
        params: {
          ...params,
        },
      })
    },
    canCreateForm() {
      const { resource } = this.selectedForm
      return this.$can('create', resource)
    },
    canViewFormDetails() {
      const { resource } = this.selectedForm
      return this.$can('read', resource)
    },
    async getListFormulaires() {
      this.updateFilter()
      const params = { ...this.query }
      this.tableData = []
      this.isLoading = true
      if (!this.selectedForm) return
      const { apiRoutePrefixe } = this.selectedForm
      if (!apiRoutePrefixe) return
      this.model = apiRoutePrefixe
      // console.log("prefix",  this.selectedForm )
      let filterLength = params.filter.type.length
      let foundEtablissement = false
      if(!this.isPromoteur || !this.isAgent || filterLength != 1){
        let indexFound = null
        params.filter.type.map((element,index) => {
          if(element == 'etablissement'){
            indexFound = index
          }
        })
        if(indexFound){
          params.filter.type.splice(indexFound,1)
          params.filter.text.splice(indexFound,1)
        }
      }
      // console.log('::: ⭕️⭕️⭕️', this.userData, this.isAgentDdtca);
      console.log('params::: ⭕️', params);
      await this.fetchFormulaires({
        formType: apiRoutePrefixe,
        params:
          {
            ...params,
            page: this.paginationData.currentPage - 1,
            perPage: this.paginationData.perPage,
            filterStyleAnd : this.filterStyleAnd ? "$and" : "$or"
          } || null,
      })
        .then(res => {
          console.log('res::: ⭕️', res.data.resources);
          this.isLoading = false

          if (res.status == 200) {
            this.tableData = []
            this.tableData = res.data.resources
            this.totalRows = res.data.total

            this.paginationData.total = res.data.total || res.data.resources.length || 0
            if (this.paginationData.metaData.from == 0) {
              this.paginationData.metaData.from += 1
            } else {
              this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
                - this.paginationData.perPage
            }
            this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
              + this.tableData.length
          }
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    uploadData(action) {
      this.loadingExport = true
      const { apiRoutePrefixe } = this.selectedForm
      if (!apiRoutePrefixe) {
        this.loadingExport = false
        return
      }
      if( action === 'allData'){
        this.query.filter.type = []
        this.query.filter.text = []
      }
      if( action === 'filteredData' ){
        // haveFilter = true
        this.updateFilter()
      }
      this.exportFormulaires({
        formType: apiRoutePrefixe,
        params: { ...this.query }
      })
        .then(async res => {
          if (res.status == 200) {
            this.loadingExport = false
            try {
              const blob = res.data
              const fileName = `${this.slug}.csv`
              const newBlob = new Blob([blob])
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
              } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob)
                const link = document.createElement('a')
                link.href = objUrl
                link.download = fileName
                link.click()
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                  window.URL.revokeObjectURL(objUrl)
                }, 250)
              }
            } catch (error) {console.log('error: ', error)}
          }
        })
        .catch(err => {
          this.loadingExport = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateFilter() {
      this.query.filter = {
        text: [],
        type: [],
      }

      const etablissementsFilter = () => {
        const { nomEtablissement, _id } = this.filterBy.nomEtablissement || {}
        return {
          [this.estUneFicheCliente ? 'etablissement' : 'nomEtablissement']: this
            .estUneFicheCliente
            ? _id || ''
            : nomEtablissement || '',
        }
      }
      const periodeFilter = () => {
        const { from, to } = this.filterBy.periode
        if (!from && !to) return { createdAt: '' }
        const result = !to ? `${from},${from}` : `${from},${to}`
        return { createdAt: result }
      }

      /**
       *! Make sure that only data that belongs to the connected user will be retrieved
       *! Its applied to etablissement users only
       */
      const abilityFilter = () => {
        const {
          isEtablissementUser, etablissementId, isCommissariatUser, isCommissaire, currentUserId,
          isAgentDdtca
        } = utilsService.currentUserUtils()
        const { type, sousType } = this.selectedForm

        if (isCommissariatUser) return { commissariat: currentUserId }
        if (isAgentDdtca) {
          if(sousType === 'frontiere') return { departement: this.userData.associated_department }
          if(type === 'client') return { departement: this.userData.associated_department }

          if(type === 'etablissement') return { departement: this.userData.associated_department }
        }

        return {}
      }

      let filters = {
        ...(this.filterBy || {}),
      }

      delete filters.periode
      delete filters.nomEtablissement
      filters = {
        ...filters,
        ...etablissementsFilter(),
        ...periodeFilter(),
        ...abilityFilter(),
        commissariat: this.filterBy.commissariat,
        dateArrivee: this.reverseDate(this.filterBy.dateArrivee),
        date: this.reverseDate(this.filterBy.date),
      }

      const filtersObj = buildFiltersForRequest(filters)
      
      this.query = {
        ...this.query,
        filter: filtersObj,
      }
    },

    onDecode(decodedString) {
      if (decodedString) {
        this.loadQR = true
        const params = decodedString.split('|')
        this.$router.push({
          name: 'formulaires-details',
          params: { id: params[0], model: this.selectedForm.apiRoutePrefixe },
        })
      }
    },
    onDragOver(draggingOver) {
      this.draggingOver = draggingOver
    },
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val }
    },

    async fetchDatasForStats(page, perPage) {
      if (!this.selectedForm) return
      const { apiRoutePrefixe } = this.selectedForm
      
      const apiRoutePrefixeWithoutDash = apiRoutePrefixe.replace('-', '')
      if (!apiRoutePrefixe) return
      await this.fetchFormulaires({
        formType: apiRoutePrefixe,
        params: { page, perPage } || null,
      })
        .then(res => {
          this.statsLoad = res.data.resources
        })
        .catch(err => {
          console.log(err)
        })
    },
    resetListWhenResetFilter(){
      this.getListFormulaires()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/pages/app-invoice.scss';
.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;
  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}
.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;
    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}
.d-content {
  display: contents;
}
#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;
  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }
      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
